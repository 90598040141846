<template>

  <div>

    <el-button
      @click="goToIdP"
    >
      Войти с помощью SSO
    </el-button>

<!--    <el-button-->
<!--      @click="logout"-->
<!--    >-->
<!--      выйти-->
<!--    </el-button>-->

  </div>
</template>

<script>


import requestSender from "@/api/base/requestSender";
import {authMethod} from "@/mixins/authMethod";

export default {
  name: "oauth1",
  mixins: [authMethod],

  props: {},
  data() {
    return {
      code: null,
    }
  },

  beforeMount() {
    this.code = this.$route.query.code;
    if( this.code ){
      this.checkCode();
    } else {
      const authMethodsCount = this.$companyConfiguration.auth.getEnabledAuthMethods().length;
      if( authMethodsCount === 1 ){
        this.goToIdP();
      }
    }
  },
  methods: {
    goToIdP(){
      this.$store.dispatch('setLoadingLogin', true);
      const methodConfig = this.$companyConfiguration.auth.getAuthMethodConfig('oauth1');
      window.location.href = this.$companyConfiguration.helpers.buildUrl(
        methodConfig.domain+'/oauth2/authorize',
        {
          client_id: methodConfig.clientId,
          redirect_uri: methodConfig.redirectUri,
          response_type: 'code',
          resource: methodConfig.resource,
        }
      )
    },

    checkCode(){
      this.$store.dispatch('setLoadingLogin', true);
      requestSender('post', 'auth/oauth1/login', {
        code: this.code,
      })
        .then(data => {
          this.processAuthSuccess(data, 'oauth1');
          // localStorage.setItem('jwt', data.token);
          // // localStorage.setItem('refresh_token', data.refresh_token);
          // localStorage.setItem('last_login_method', 'oauth1');
          //
          // this.$router.push({name: 'Home'});
        })
        .catch(()=>{
          this.$router.push({name: 'Login'});
        })
        .finally(()=>{
          this.$store.dispatch('setLoadingLogin', false);
        })
    },

  },

}
</script>

<style>

</style>